<template>
  <div class="top">
    <div class="top-wrap flex ac">
      <div class="top-left flex flex1">
        <div class="flex ac mgr10"><img class="logo" :src="require('@/assets/1.png')" /></div>
        <div class="flex ac f24 bold">XINGTOOL管理后台</div>
      </div>
      <div class="flex1 text-right">
        <el-button size="mini" type="primary" round @click="goPage('Login')"
          >登录</el-button
        >
      </div>
    </div>
    <div class="bg-top-img-box">
      <img :src="require('@/assets/back.jpeg')" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  created() {
  },
  methods: {
    goPage: function (name) {
      var t = new Date().getTime();
      this.$router.push({ name: name, query: { t: t } });
    },
  },
};
</script>
<style scoped>
.top {
  border-bottom: 1px solid #ececec;
  height: 60px;
}
.top-wrap {
  max-width: 90%;
  margin: 0 auto;
  height: 100%;
  position: relative;
  z-index: 100;
}
.logo {
  height: 47px;
}
.bg-top-img-box {
  position: absolute;
  right: 0%;
  top: 0;
  height: 100%;
}
.bg-top-img-box > img {
  height: 100%;
}
</style>