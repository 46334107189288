<template>
  <Main>
    <div slot="left">
      <p class="title">搜索流量7天破千</p>
      <p class="desc">
        安心优化搜索，精准用户标签，快速增加店铺基础热度，提升搜索权重
      </p>
    </div>
    <div slot="right">
      <p class="r-title">修改密码</p>
      <div class="pdt20">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="username">
            <el-input
              class="input"
              prefix-icon="el-icon-mobile-phone"
              v-model="form.username"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              class="input"
              prefix-icon="el-icon-unlock"
              v-model="form.password"
              placeholder="请输入密码"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item prop="repassword">
            <el-input
              class="input"
              prefix-icon="el-icon-unlock"
              v-model="form.repassword"
              placeholder="请再次输入密码"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item class="rl" prop="code">
            <el-input
              class="input"
              v-model="form.code"
              placeholder="请输入短信验证码"
            >
            </el-input>
            <span class="vcode" @click="getMobileCode">{{ lockMsg }}</span>
          </el-form-item>
        </el-form>
      </div>
      <div class="pdt120">
        <el-button size="medium" class="login" type="primary" @click="submit"
          >提交</el-button
        >
        <div class="mgt10 text-right">
          <span class="gray">已有账号？</span>
          <el-link type="primary" :underline="false" @click="goPage('Login')"
            >马上登录</el-link
          >
        </div>
      </div>
    </div>
  </Main>
</template>
<script>
import Main from "@/components/front/main";
export default {
  components: {
    Main,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
        repassword: "",
        code: "",
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        repassword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      lock: false,
      lockMsg: "获取验证码",
      url:{
        changePassword:"/business/auth/changePassword.html",
        mobileCodeCheckUser:"/business/auth/mobileCodeCheckUser.html",
      }
    };
  },
  created(){
    this.enterKey();
  },
  methods: {
    goPage: function (name) {
      this.$router.push({ name: name });
    },
    enterKey: function () {
      document.onkeydown = () => {
        var key = window.event.keyCode;
        if (key == 13) {
          this.submit();
        }
      };
    },
    submit: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$rq
            .postAction(this.url.changePassword, this.form)
            .then((rs) => {
              if (rs.code == 0) {
                //登录成功
                this.$message({
                  message: rs.msg,
                  type: "success",
                });
                this.$router.push({
                  name: "Login",
                  query: { t: new Date().getTime() },
                });
              } else {
                this.$message({
                  message: rs.msg,
                  type: "warning",
                });
              }
            });
        }
      });
    },
    showTime: function (rs) {
      var t = rs.expire - parseInt(new Date().getTime() / 1000);
      if (t > 0) {
        this.lockMsg = t + "秒后获取";
        setTimeout(() => {
          this.showTime(rs);
        }, 1000);
      } else {
        this.lockMsg = "获取验证码";
        this.lock = false;
      }
    },
    getMobileCode: function () {
      //获得短信验证码
      if (this.form.username == "") {
        this.$message({
          type: "warning",
          message: "请输入手机号码",
        });
        return;
      }
      if (!this.$func.checkMobile(this.form.username)) {
        this.$message({
          type: "warning",
          message: "手机号码不正确",
        });
        return;
      }
      if (!this.lock) {
        this.lock = true;
        this.$rq
          .postAction(this.url.mobileCodeCheckUser, {
            mobile: this.form.username,
          })
          .then((rs) => {
            if (rs.code == 0) {
              this.showTime(rs);
            } else {
              this.lock = false;
              this.$message({
                type: "warning",
                message: rs.msg,
              });
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.title {
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
}
.r-title {
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 2px;
  color: #222;
}
.desc {
  font-size: 16px;
  margin-top: 20px;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  color: #b2b2b2;
}
.login {
  width: 100%;
  padding: 16px 0px;
}
.input .el-input__inner {
  font-size: 16px;
}
.gray {
  color: gray;
  font-size: 14px;
}
.vcode {
  color: #c0c4cc;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>