<template>
  <div>
    <Top></Top>
    <div class="flex">
      <div class="left flex1">
        <div class="box">
          <div class="box-text">
            <slot name="left"></slot>
          </div>
          <div class="bg-bottom-img"></div>
        </div>
      </div>
      <div class="right flex1">
        <div class="right-box">
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Top from "./top.vue";
export default {
  data() {
    return {
      url: {
        token: "/account/token",
      },
    };
  },
  components: {
    Top,
  },
  created() {
    this.token();
  },
  methods: {
    token: function () {
      this.$rq.postAction(this.url.token).then();
    },
  },
};
</script>
<style scoped>
.left {
  margin-top: 30px;
  padding-left: 160px;
  box-sizing: border-box;
}
.right,
.left {
  width: 50%;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.bg-bottom-img {
  width: 680px;
  height: 500px;
  text-align: left;
  background: url("~@/assets/login.jpeg");
  background-size: 120%;
  background-position-y: -90px;
  background-position-x: -30px;
}
.right-box {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 140px auto 0;
  z-index: 10;
  width: 60%;
  padding: 30px 30px 20px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 2px 10px 2px rgb(0 0 0 / 20%);
  overflow: hidden;
  background-color: #fff;
  z-index: 200;
}
</style>